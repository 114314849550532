"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var react_native_1 = require("react-native");
var expo_linear_gradient_1 = require("expo-linear-gradient");
var Constants_1 = require("./Constants");
var styles = react_native_1.StyleSheet.create({
    absoluteGradient: {
        height: "100%",
        position: "absolute",
        width: "100%",
    },
    container: {
        alignItems: "center",
        flex: 1,
        justifyContent: "center",
    },
    gradientChild: {
        flex: 1,
    },
});
var getInitialState = function (_a) {
    var isLoading = _a.isLoading, layout = _a.layout;
    return ({
        isLoading: isLoading,
        layout: layout,
    });
};
var getDefaultProps = function () { return ({
    containerStyle: styles.container,
    easing: Constants_1.DEFAULT_EASING,
    duration: Constants_1.DEFAULT_DURATION,
    layout: [],
    animationType: Constants_1.DEFAULT_ANIMATION_TYPE,
    animationDirection: Constants_1.DEFAULT_ANIMATION_DIRECTION,
    isLoading: Constants_1.DEFAULT_LOADING,
    boneColor: Constants_1.DEFAULT_BONE_COLOR,
    highlightColor: Constants_1.DEFAULT_HIGHLIGHT_COLOR,
    intensity: Constants_1.DEFAULT_INTENSITY,
}); };
var SkeletonContent = /** @class */ (function (_super) {
    __extends(SkeletonContent, _super);
    function SkeletonContent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = getInitialState(_this.props);
        _this.animationPulse = new react_native_1.Animated.Value(0);
        _this.animationShiver = new react_native_1.Animated.Value(0);
        _this.interpolatedBackgroundColor = _this.animationPulse.interpolate({
            inputRange: [0, 1],
            outputRange: [_this.props.boneColor, _this.props.highlightColor],
        });
        _this.getGradientStartDirection = function () {
            var direction = { x: 0, y: 0 };
            if (_this.props.animationType === "shiver") {
                if (_this.props.animationDirection === "horizontalLeft" ||
                    _this.props.animationDirection === "horizontalRight" ||
                    _this.props.animationDirection === "verticalTop" ||
                    _this.props.animationDirection === "verticalDown" ||
                    _this.props.animationDirection === "diagonalDownRight") {
                    direction = { x: 0, y: 0 };
                }
                else if (_this.props.animationDirection === "diagonalTopLeft") {
                    direction = { x: 1, y: 1 };
                }
                else if (_this.props.animationDirection === "diagonalTopRight") {
                    direction = { x: 0, y: 1 };
                }
                else if (_this.props.animationDirection === "diagonalDownLeft") {
                    direction = { x: 1, y: 0 };
                }
            }
            return direction;
        };
        _this.getGradientEndDirection = function () {
            var direction = { x: 0, y: 0 };
            if (_this.props.animationType === "shiver") {
                if (_this.props.animationDirection === "horizontalLeft" ||
                    _this.props.animationDirection === "horizontalRight" ||
                    _this.props.animationDirection === "diagonalTopRight") {
                    direction = { x: 1, y: 0 };
                }
                else if (_this.props.animationDirection === "verticalTop" ||
                    _this.props.animationDirection === "verticalDown" ||
                    _this.props.animationDirection === "diagonalDownLeft") {
                    direction = { x: 0, y: 1 };
                }
                else if (_this.props.animationDirection === "diagonalTopLeft") {
                    direction = { x: 0, y: 0 };
                }
                else if (_this.props.animationDirection === "diagonalDownRight") {
                    direction = { x: 1, y: 1 };
                }
            }
            return direction;
        };
        _this.gradientStart = _this.getGradientStartDirection();
        _this.gradientEnd = _this.getGradientEndDirection();
        _this.playAnimation = function () {
            if (_this.props.animationType === "pulse") {
                react_native_1.Animated.loop(react_native_1.Animated.sequence([
                    react_native_1.Animated.timing(_this.animationPulse, {
                        toValue: 1,
                        duration: _this.props.duration / 2,
                        easing: _this.props.easing,
                        delay: _this.props.duration,
                    }),
                    react_native_1.Animated.timing(_this.animationPulse, {
                        toValue: 0,
                        easing: _this.props.easing,
                        duration: _this.props.duration / 2,
                    }),
                ])).start();
            }
            else {
                react_native_1.Animated.loop(react_native_1.Animated.timing(_this.animationShiver, {
                    toValue: 1,
                    duration: _this.props.duration,
                    easing: _this.props.easing,
                })).start();
            }
        };
        _this.getBoneStyles = function (boneLayout) {
            var boneStyle = __assign({ width: boneLayout.width || 0, height: boneLayout.height || 0, borderRadius: boneLayout.borderRadius || Constants_1.DEFAULT_BORDER_RADIUS }, boneLayout);
            if (_this.props.animationType === "pulse") {
                boneStyle.backgroundColor = _this.interpolatedBackgroundColor;
            }
            else {
                boneStyle.overflow = "hidden";
                boneStyle.backgroundColor = boneLayout.backgroundColor || _this.props.boneColor;
            }
            return boneStyle;
        };
        _this.getGradientTransform = function (boneLayout) {
            var transform = {};
            var interpolatedPosition = _this.animationShiver.interpolate({
                inputRange: [0, 1],
                outputRange: _this.getPositionRange(boneLayout),
            });
            if (_this.props.animationDirection !== "verticalTop" && _this.props.animationDirection !== "verticalDown") {
                transform = { translateX: interpolatedPosition };
            }
            else {
                transform = { translateY: interpolatedPosition };
            }
            return transform;
        };
        _this.getPositionRange = function (boneLayout) {
            var outputRange = [];
            var boneWidth = boneLayout.width || 0;
            var boneHeight = boneLayout.height || 0;
            if (_this.props.animationDirection === "horizontalRight" ||
                _this.props.animationDirection === "diagonalDownRight" ||
                _this.props.animationDirection === "diagonalTopRight") {
                outputRange.push(-boneWidth, +boneWidth);
            }
            else if (_this.props.animationDirection === "horizontalLeft" ||
                _this.props.animationDirection === "diagonalDownLeft" ||
                _this.props.animationDirection === "diagonalTopLeft") {
                outputRange.push(+boneWidth, -boneWidth);
            }
            else if (_this.props.animationDirection === "verticalDown") {
                outputRange.push(-boneHeight, +boneHeight);
            }
            else if (_this.props.animationDirection === "verticalTop") {
                outputRange.push(+boneHeight, -boneHeight);
            }
            return outputRange;
        };
        _this.getStaticBone = function (layoutStyle, key) { return (<react_native_1.Animated.View key={layoutStyle.key || key} style={_this.getBoneStyles(layoutStyle)}/>); };
        _this.getShiverBone = function (layoutStyle, key) { return (<react_native_1.View key={layoutStyle.key || key} style={_this.getBoneStyles(layoutStyle)}>
      <react_native_1.Animated.View style={[
            styles.absoluteGradient,
            {
                transform: [_this.getGradientTransform(layoutStyle)],
            },
        ]}>
        <expo_linear_gradient_1.LinearGradient colors={[_this.props.boneColor, _this.props.highlightColor, _this.props.boneColor]} start={_this.gradientStart} end={_this.gradientEnd} style={styles.gradientChild}/>
      </react_native_1.Animated.View>
    </react_native_1.View>); };
        _this.getBones = function (layout, children) {
            if (layout.length > 0) {
                var iterator = new Array(layout.length);
                for (var i = 0; i < layout.length; i++) {
                    iterator[i] = 0;
                }
                return iterator.map(function (_, i) {
                    if (_this.props.animationType === "pulse" || _this.props.animationType === "none") {
                        return _this.getStaticBone(layout[i], i);
                    }
                    else {
                        return _this.getShiverBone(layout[i], i);
                    }
                });
            }
            else {
                return React.Children.map(children, function (child, i) {
                    var styling = child.props.style || {};
                    if (_this.props.animationType === "pulse" || _this.props.animationType === "none") {
                        return _this.getStaticBone(styling, i);
                    }
                    else {
                        return _this.getShiverBone(styling, i);
                    }
                });
            }
        };
        _this.renderLayout = function (isLoading, bones, children) {
            return isLoading ? bones : children;
        };
        return _this;
    }
    SkeletonContent.getDerivedStateFromProps = function (nextProps, prevState) {
        if (nextProps.isLoading !== prevState.isLoading || nextProps.layout !== prevState.layout) {
            return { isLoading: nextProps.isLoading, layout: nextProps.layout };
        }
        return null;
    };
    SkeletonContent.prototype.componentDidMount = function () {
        this.playAnimation();
    };
    SkeletonContent.prototype.render = function () {
        var _a = this.state, isLoading = _a.isLoading, layout = _a.layout;
        var children = this.props.children;
        var bones = this.getBones(layout, children);
        return <react_native_1.View style={this.props.containerStyle}>{this.renderLayout(isLoading, bones, children)}</react_native_1.View>;
    };
    SkeletonContent.defaultProps = getDefaultProps();
    return SkeletonContent;
}(React.Component));
exports.default = SkeletonContent;
